import {
  SEARCH_QADB_INIT,
  SEARCH_QADB_SUCCESS,
  SEARCH_QADB_ERROR,
  SEARCH_QADB_ON_BLUR,
  SEARCH_QADB_ON_FOCUS,
  SEARCH_QADB_CLOSE,
  SEARCH_QADB_CLOSE_COMPLETE,
  SEARCH_QADB_CALL_TO_ACTION_FOCUS,
  CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP,
  LEAVE_FEEDBACK_COMPLETE,
  MAKE_QUESTION_SUCCESS,
  MAKE_QUESTION_ERROR,
  MAKE_QUESTION,
  SET_SMART_ANSWER,
  CLOSE_SMART_ANSWER_MODAL,
  OPEN_SMART_ANSWER_MODAL,
  OPEN_QUESTION_FROM_SA_MODAL,
} from '../../../../components/qadb/actions/actions';
import {
  QUICK_ACTIONS,
  SEARCH_BAR,
  RESULTS,
  CARD_ANSWER,
  MAKE_QUESTION_SA,
  SMART_ANSWER_MODAL,
} from '../../../../components/qadb/qadb.constants';

const searchQadbInit = (state, action) => {
  const searchbar = state.components.qadb.searchBar
    ? state.components.qadb.searchBar
    : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);

  const quickActions =
    state.components.qadb.quickActions ||
    state.components.qadb.qadb_data.components.find(component => component.id === QUICK_ACTIONS);

  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        isFetching: true,
        query: action.payload.query,
        searchBar: { ...searchbar, tooltip: null },
        quickActions: { ...quickActions },
      },
    },
  };
};

const searchQadbSuccess = (state, action) => {
  const searchbar = state.components.qadb.searchBar
    ? state.components.qadb.searchBar
    : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);
  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        qadb_data: {
          ...action.payload.response,
        },
        searchBar: { ...searchbar, showClose: true },
        smartAnswerModal: {
          isOpen: true,
          isAsking: false,
        },
        isFetching: false,
      },
    },
  };
};

const searchQadbError = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      isFetching: false,
    },
  },
});

const searchQadbOnBlur = state => {
  const searchbar = state.components.qadb.searchBar
    ? state.components.qadb.searchBar
    : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);

  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        searchBar: { ...searchbar, focus: false },
      },
    },
  };
};

const searchQadbOnFocus = state => {
  const searchbar = state.components.qadb.searchBar
    ? state.components.qadb.searchBar
    : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);

  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        searchBar: { ...searchbar, focus: true },
      },
    },
  };
};

const searchQadbClose = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      searchBar: { ...state.components.qadb.searchBar, showClose: false },
      query: '',
      isFetching: true,
      smartAnswerModal: { isOpen: false, isAsking: false },
    },
  },
});

const searchQadbCloseComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      qadb_data: {
        ...action.payload.response,
      },
      searchBar: {
        ...action.payload.response.components.find(component => component.id === SEARCH_BAR),
        focus: true,
        showClose: false,
        key: Math.random(),
      },
      isFetching: false,
    },
  },
});

const leaveFeedbackComplete = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      qadb_data: {
        components: state.components.qadb.qadb_data.components.map(component => {
          if (component.id === RESULTS) {
            const newResults = {
              ...component,
              components: component.components.map(innerComponent => {
                if (innerComponent.id === CARD_ANSWER) {
                  const newCardAnswer = {
                    ...innerComponent,
                    smart_answers: innerComponent.smart_answers.map(smart_answer => ({
                      ...smart_answer,
                      showFeedbackMessage: true,
                    })),
                  };
                  return newCardAnswer;
                }
                return innerComponent;
              }),
            };
            return newResults;
          }
          return component;
        }),
      },
    },
  },
});

const searchQadbCallToActionFocus = (state, action) => {
  const searchbar = state.components.qadb.searchBar
    ? state.components.qadb.searchBar
    : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);

  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        searchBar: { ...searchbar, focus: true, tooltip: action.tooltip },
      },
    },
  };
};

const closeHowToMakeQuestionTooltip = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      searchBar: {
        ...state.components.qadb.searchBar,
        tooltip: { ...state.components.qadb.searchBar.tooltip, visible: false },
        focus: false,
      },
    },
  },
});

const fetchMakeQuestion = (state, action) => {
  const makeQuestion = state.components.qadb.qadb_data.components.find(component => component.id === MAKE_QUESTION_SA);
  makeQuestion.initialValue = action.payload.question;

  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        makequestion: {
          ...state.components.qadb.makequestion,
          disabled: true,
        },
      },
    },
  };
};

const fetchMakeQuestionSuccess = (state, action) => {
  const components = Array.from(state.components.qadb.qadb_data.components);

  const makeQuestion = state.components.qadb.qadb_data.components.find(component => component.id === MAKE_QUESTION_SA);
  makeQuestion.initialValue = '';

  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        components: [components.filter(component => component.id !== 'RESULTS'), action.payload.results],
        snackMessage: {
          key: Math.random(),
          show: true,
          type: 'success',
          message: action.payload.message,
        },
        query: '',
        smartAnswerModal: { isOpen: false, isAsking: false },
        makequestion: {
          ...state.components.qadb.makequestion,
          disabled: false,
        },
      },
    },
  };
};

const setSmartAnswer = (state, action) => {
  let smart = state.components.qadb.qadb_data.components.find(component => component.id === SMART_ANSWER_MODAL);
  const makeQuestion = state.components.qadb.qadb_data.components.find(component => component.id === MAKE_QUESTION_SA);

  makeQuestion.initialValue = '';

  if (smart) {
    smart = action.payload;
  } else {
    state.components.qadb.qadb_data.components.push(action.payload);
  }

  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        smartAnswer: action.payload,
        smartAnswerModal: { isOpen: true, isAsking: false },
        makequestion: {
          ...state.components.qadb.makequestion,
          disabled: false,
        },
      },
    },
  };
};

const fetchMakeQuestionError = (state, action) => {
  const makeQuestion = state.components.qadb.qadb_data.components.find(component => component.id === MAKE_QUESTION_SA);
  makeQuestion.initialValue = '';
  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        snackMessage: { key: Math.random(), show: true, type: 'error', message: action.payload.message },
        makequestion: {
          ...state.components.qadb.makequestion,
          disabled: false,
        },
      },
    },
  };
};

const closeSmartAnswerModal = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      smartAnswerModal: {
        isOpen: false,
        isAsking: false,
      },
    },
  },
});

const openSmartAnswertModal = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      smartAnswerModal: {
        isOpen: true,
        isAsking: false,
      },
    },
  },
});

const openQuestionFromSaModal = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      smartAnswerModal: {
        isOpen: false,
        isAsking: true,
      },
    },
  },
});

const qadbMapper = {
  [SEARCH_QADB_INIT]: searchQadbInit,
  [SEARCH_QADB_SUCCESS]: searchQadbSuccess,
  [SEARCH_QADB_ERROR]: searchQadbError,
  [SEARCH_QADB_ON_BLUR]: searchQadbOnBlur,
  [SEARCH_QADB_ON_FOCUS]: searchQadbOnFocus,
  [SEARCH_QADB_CLOSE]: searchQadbClose,
  [SEARCH_QADB_CLOSE_COMPLETE]: searchQadbCloseComplete,
  [LEAVE_FEEDBACK_COMPLETE]: leaveFeedbackComplete,
  [SEARCH_QADB_CALL_TO_ACTION_FOCUS]: searchQadbCallToActionFocus,
  [CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP]: closeHowToMakeQuestionTooltip,
  [MAKE_QUESTION]: fetchMakeQuestion,
  [MAKE_QUESTION_SUCCESS]: fetchMakeQuestionSuccess,
  [SET_SMART_ANSWER]: setSmartAnswer,
  [MAKE_QUESTION_ERROR]: fetchMakeQuestionError,
  [CLOSE_SMART_ANSWER_MODAL]: closeSmartAnswerModal,
  [OPEN_SMART_ANSWER_MODAL]: openSmartAnswertModal,
  [OPEN_QUESTION_FROM_SA_MODAL]: openQuestionFromSaModal,
};

export {
  searchQadbInit,
  searchQadbSuccess,
  searchQadbError,
  searchQadbOnBlur,
  searchQadbOnFocus,
  searchQadbClose,
  searchQadbCloseComplete,
  leaveFeedbackComplete,
  searchQadbCallToActionFocus,
  closeHowToMakeQuestionTooltip,
  fetchMakeQuestion,
  fetchMakeQuestionSuccess,
  setSmartAnswer,
  fetchMakeQuestionError,
  closeSmartAnswerModal,
  openSmartAnswertModal,
  openQuestionFromSaModal,
};
export default qadbMapper;
