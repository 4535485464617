import React from 'react';
import { string, shape } from 'prop-types';
import { PROFILE_HEADER } from '../utils/component-types';
import resources from '../utils';
import { constants } from '../../../utils/constants';

const wrapSticky = comp => <div className="wrapper-sticky">{comp}</div>;

const ComponentFactory = ({ component, properties, siteId }) => {
  const source = resources[component];
  const { API_BASE_PATH } = constants;

  if (source) {
    const isPH = component === PROFILE_HEADER;
    const { is_repurchase = false, ...rest } = properties;
    let props = isPH ? { ...properties, baseURL: API_BASE_PATH } : { ...rest, isRepurchase: is_repurchase };
    props = { ...props, siteId };
    const params = component.children?.length > 0 ? [source, props, component.children] : [source, props];

    if (isPH) {
      return wrapSticky(React.createElement(...params));
    }

    return React.createElement(...params);
  }
  return null;
};

ComponentFactory.propTypes = {
  component: string.isRequired,
  properties: shape.isRequired,
  siteId: string.isRequired,
};

export default ComponentFactory;
