import { ON_DEMAND_IFRAME, CART_CONGRATS_UPDATE, FETCH_ON_DEMAND_IFRAME } from '../../../../actions/vip/types';

const onDemandIframe = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    on_demand_iframe: {
      ...state.components.on_demand_iframe,
      ...action.params,
    },
  },
  isFetching: typeof action.isFetching !== 'undefined' ? action.isFetching : state.isFetching,
});

const cartCongratsUpdate = (state, action) => ({
  ...state,
  parentUrl: action.payload.parentUrl,
  selected_offer_type: action.payload.selected_offer_type,
  components: {
    ...state.components,
    ...action.payload.components,
    payment_summary: {
      styles: state.components.payment_summary.styles,
      ...action.payload.components.payment_summary,
    },
  },
  buttonFocus: true,
});

const fetchOnDemandIframe = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    main_actions: {
      ...state.components.main_actions,
      actions: state.components.main_actions.actions.map(act =>
        act.label.text === action.params.labelText ? { ...act, loading: action.params.loading } : act,
      ),
    },
  },
  isFetching: action.params.isFetching,
});

const onDemandIframeMapper = {
  [ON_DEMAND_IFRAME]: onDemandIframe,
  [CART_CONGRATS_UPDATE]: cartCongratsUpdate,
  [FETCH_ON_DEMAND_IFRAME]: fetchOnDemandIframe,
};

export { onDemandIframe, cartCongratsUpdate, fetchOnDemandIframe };
export default onDemandIframeMapper;
