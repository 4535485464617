import {
  FETCH_COMPATS_SEARCH_START,
  FETCH_COMPATS_SEARCH_STICKY_START,
  FETCH_COMPATS_DELETE_START,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE,
  FETCH_COMPATS_SEARCH_COMPLETE,
  FETCH_COMPATS_SEARCH_COMPLETE_MOBILE,
  FETCH_COMPATS_DELETE_COMPLETE,
  FETCH_COMPATS_DELETE_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_ERROR,
  FETCH_COMPATS_DELETE_ERROR,
  FETCH_COMPATS_FEEDBACK_CLOSE,
} from '../../../../actions/vip/types';
import { getUpdatedCompatsMobileState, getUpdatedCompatsPdpState } from '../../../helpers/compats-utils';

const fetchCompatsStart = state => ({ ...state });

const fetchCompatsError = state => ({
  ...state,
  components: {
    ...state.components,
    compats_widget: {
      ...state.components.compats_widget,
      widget_data: {
        ...state.components.compats_widget.widget_data,
        error: true,
      },
    },
  },
});

const fetchCompatsFeedbackClose = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    compats_feedback: {
      ...action.payload.updateState,
    },
  },
});

const compatsWidgetMapper = {
  [FETCH_COMPATS_SEARCH_START]: fetchCompatsStart,
  [FETCH_COMPATS_SEARCH_STICKY_START]: fetchCompatsStart,
  [FETCH_COMPATS_DELETE_START]: fetchCompatsStart,
  [FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE]: getUpdatedCompatsMobileState,
  [FETCH_COMPATS_SEARCH_STICKY_COMPLETE]: getUpdatedCompatsPdpState,
  [FETCH_COMPATS_SEARCH_COMPLETE]: getUpdatedCompatsPdpState,
  [FETCH_COMPATS_SEARCH_COMPLETE_MOBILE]: getUpdatedCompatsMobileState,
  [FETCH_COMPATS_DELETE_COMPLETE]: getUpdatedCompatsPdpState,
  [FETCH_COMPATS_DELETE_COMPLETE_MOBILE]: getUpdatedCompatsMobileState,
  [FETCH_COMPATS_SEARCH_ERROR]: fetchCompatsError,
  [FETCH_COMPATS_DELETE_ERROR]: fetchCompatsError,
  [FETCH_COMPATS_FEEDBACK_CLOSE]: fetchCompatsFeedbackClose,
};

export { fetchCompatsStart, fetchCompatsError, fetchCompatsFeedbackClose };
export default compatsWidgetMapper;
