import {
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  SET_PICKERS_COLLAPSED,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
} from '../../../../actions/actions';
import { hasVariationsPickers, hasVisibleVariations } from '../../../helpers/components';
import findActionSelected from '../../../helpers/main-actions';

const showVariantionsErrorDesktop = state => {
  if (!hasVariationsPickers(state)) {
    return state;
  }
  return {
    ...state,
    components: {
      ...state.components,
      variations: {
        ...state.components.variations,
        pickers: state.components.variations.pickers.map(picker => {
          if (!picker.error_message) {
            return picker;
          }
          return {
            ...picker,
            error_message: {
              ...picker.error_message,
              is_deferred: picker.error_message.is_deferred ? null : picker.error_message.is_deferred,
            },
          };
        }),
      },
    },
  };
};

const showVariantionsErrorMobile = (state, action) => {
  const variationsComponent = hasVisibleVariations(state);
  const hasPickers = state.components[variationsComponent].pickers?.length > 0;
  if (!hasPickers) {
    return state;
  }
  return {
    ...state,
    components: {
      ...state.components,
      [variationsComponent]: {
        ...state.components[variationsComponent],
        pickers: state.components[variationsComponent].pickers.map(picker => {
          if (!picker.error_message) {
            return picker;
          }
          return {
            ...picker,
            error_message: {
              ...picker.error_message,
              is_deferred: picker.error_message.is_deferred ? null : picker.error_message.is_deferred,
            },
          };
        }),
        requestKey: Math.random(),
        actionSelected: findActionSelected(state, action.payload.actionKey),
      },
    },
  };
};

const setPickersCollapsed = (state, action) => ({
  ...state,
  variations: {
    ...state.components.variations,
    pickers: state.components.variations.pickers.map(picker => {
      if (picker.id !== action.pickerId) {
        picker.display_info.is_collapsed = !picker.display_info.is_collapsed;
      }
      return picker;
    }),
  },
});

const fetchProductVariationsDetails = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    ...action.payload?.components,
  },
  isFetching: false,
});

const variationsMapper = {
  [SHOW_VARIATIONS_ERROR_DESKTOP]: showVariantionsErrorDesktop,
  [SHOW_VARIATIONS_ERROR_MOBILE]: showVariantionsErrorMobile,
  [SET_PICKERS_COLLAPSED]: setPickersCollapsed,
  [FETCH_PRODUCT_VARIATIONS_DETAILS]: fetchProductVariationsDetails,
};

export { showVariantionsErrorDesktop, showVariantionsErrorMobile, setPickersCollapsed, fetchProductVariationsDetails };
export default variationsMapper;
