import { FETCH_START, FETCH_ERROR, FETCH_COMPLETE, FETCH_END } from '../../../../actions/actions';
import { hasVisibleVariations } from '../../../helpers/components';

const fetchStart = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    html_description: undefined,
    no_stock_shops: {
      ...action.payload?.components?.no_stock_shops,
    },
  },
  isFetching: true,
  error: null,
});

const fetchError = (state, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

const fetchComplete = (state, action) => {
  const variationsComponent = hasVisibleVariations(state);
  return {
    ...state,
    id: action.payload.id,
    pathName: action.payload.pathName,
    parentUrl: action.payload.parentUrl,
    selected_offer_type: action.payload.selected_offer_type,
    components: {
      ...state.components,
      ...action.payload.components,
      enhanced_descriptions: {
        ...action.payload.components?.enhanced_descriptions,
      },
      vertical_gallery: {
        ...action.payload.components?.vertical_gallery,
      },
      main_features: action.payload.components?.main_features
        ? {
            ...state.components?.main_features,
            ...action.payload.components?.main_features,
          }
        : {
            ...{ state: 'HIDDEN' },
          },

      [variationsComponent]: action.payload.components?.[variationsComponent]
        ? {
            ...state.components[variationsComponent],
            ...action.payload.components[variationsComponent],
            pickers: action.payload.components?.[variationsComponent]?.pickers
              ? action.payload.components[variationsComponent].pickers.map(picker => {
                  if (picker.display_info) {
                    const oldDisplayInfo = state.components[variationsComponent].pickers.filter(
                      oldPicker => oldPicker.id === picker.id,
                    )[0].display_info;
                    if (oldDisplayInfo) {
                      picker.display_info.is_collapsed = oldDisplayInfo.is_collapsed;
                    }
                  }
                  return picker;
                })
              : null,
          }
        : {
            ...(state.components[variationsComponent]
              ? { ...state.components[variationsComponent] }
              : { state: 'HIDDEN' }),
          },
      html_description: {
        ...action.payload.components?.html_description,
        fetched: false,
      },
    },
    recommendations_context: { ...action.payload.recommendations_context },
    isFetching: false,
    dynamic_scroll: action.payload.dynamic_scroll,
    preload: action.payload.preload,
    error: null,
  };
};

const fetchEnd = state => ({
  ...state,
  isFetching: false,
});

const fetchStatusMapper = {
  [FETCH_START]: fetchStart,
  [FETCH_ERROR]: fetchError,
  [FETCH_COMPLETE]: fetchComplete,
  [FETCH_END]: fetchEnd,
};

export { fetchStart, fetchError, fetchComplete, fetchEnd };
export default fetchStatusMapper;
