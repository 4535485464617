import snackbarMapper from './snackbar';
import fetchStatusMapper from './fetch-status';
import bookmarkMapper from './bookmark';
// import quantityMapper from './quantity';
// import shippingMapper from './shipping';
import variationsMapper from './variations';
import fetchDeferredMapper from './fetch-deferred';

const mainMappers = {
  ...snackbarMapper,
  ...fetchStatusMapper,
  ...bookmarkMapper,
  //   ...quantityMapper,
  //   ...shippingMapper,
  ...fetchDeferredMapper,
  ...variationsMapper,
};

export default mainMappers;
