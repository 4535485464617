import { getFormattedPictures } from '../gallery/helpers/mapper';

const jsonToProps = enhancedDescriptionProps => {
  const pictures = getFormattedPictures({
    pictures: enhancedDescriptionProps.pictures,
    titleForAlt: '',
    pictureConfig: enhancedDescriptionProps.picture_config,
  });

  return {
    title: enhancedDescriptionProps.title,
    action: enhancedDescriptionProps.action,
    viewPortTrack: enhancedDescriptionProps.viewport_track,
    maxShowPixels: enhancedDescriptionProps.max_show_pixels,
    pictures: pictures.map(picture => {
      const { zoom, thumbnail, ...restPicture } = picture;
      return restPicture;
    }),
  };
};

export default jsonToProps;
