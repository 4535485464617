import get from 'lodash/get';

const removeComponents = (payload, state) => {
  const { components } = state;
  const priceExtras = get(components, 'price_extras');
  const isPriceExtras = get(payload, 'components.price_extras');
  if (priceExtras && !isPriceExtras) {
    payload.components.price_extras = { ...priceExtras, state: 'HIDDEN' };
  }
};

export { removeComponents };
