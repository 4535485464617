import {
  FETCH_ON_SET_BOOKMARK_COMPLETE,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  TOGGLE_BOOKMARKED,
} from '../../../../actions/actions';

const fetchOnSetBookmarkComplete = (state, action) => ({
  /* @TODO fix this, should return bookmark structure */
  ...state,
  components: {
    ...state.components,
    bookmark: {
      ...state.components.bookmark,
      is_bookmarked:
        action.payload.status === 'ok'
          ? state.components.bookmark.is_bookmarked
          : !state.components.bookmark.is_bookmarked,
      isFetching: false,
    },
  },
});

const toggleBookmarkOptimistically = state => ({
  ...state,
  components: {
    ...state.components,
    bookmark: {
      ...state.components.bookmark,
      is_bookmarked: !state.components.bookmark.is_bookmarked,
      isFetching: true,
    },
  },
});

const toggleBookMarked = state => ({
  ...state,
  components: {
    ...state.components,
    bookmark: {
      ...state.components.bookmark,
      is_bookmarked: !state.components.bookmark.is_bookmarked,
      isFetching: false,
    },
  },
});

const bookmarkMapper = {
  [FETCH_ON_SET_BOOKMARK_COMPLETE]: fetchOnSetBookmarkComplete,
  [TOGGLE_BOOKMARK_OPTIMISTICALLY]: toggleBookmarkOptimistically,
  [TOGGLE_BOOKMARKED]: toggleBookMarked,
};

export { toggleBookMarked, toggleBookmarkOptimistically, fetchOnSetBookmarkComplete };
export default bookmarkMapper;
