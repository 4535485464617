import { FETCH_SEQUENCER, PRELOAD_IFRAME } from '../../../../actions/actions';

const fetchSequencer = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    main_actions: {
      ...state.components.main_actions,
      actions: action.payload.actions,
    },
  },
  isFetching: action.payload.isFetching,
});

const preloadIframe = (state, action) => ({
  ...state,
  preload: {
    ...state.preload,
    ...action.params,
  },
});

const onePay4AllMapper = {
  [FETCH_SEQUENCER]: fetchSequencer,
  [PRELOAD_IFRAME]: preloadIframe,
};

export { fetchSequencer, preloadIframe };
export default onePay4AllMapper;
