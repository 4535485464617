import {
  FETCH_ON_CHANGE_INSTALLATION_COMPLETE,
  FETCH_ON_CHANGE_INSTALLATION_START,
  FETCH_ON_CHANGE_INSTALLATION_END,
} from '../../../../actions/vip/types';

const fetchOnChangeInstallationComplete = (state, action) => {
  const installations = action.payload.components.find(component => component.id === 'installations') || {};
  return {
    ...state,
    components: {
      ...state.components,
      installations: {
        ...installations,
        isFetching: false,
      },
      main_actions: {
        ...action.payload.components.find(component => component.id === 'main_actions'),
        isFetching: false,
      },
      shipping_summary: {
        ...action.payload.components.find(component => component.id === 'shipping_summary'),
        isFetching: false,
      },
      pick_up_summary: {
        ...action.payload.components.find(component => component.id === 'pick_up_summary'),
        isFetching: false,
      },
      returns_summary: {
        ...action.payload.components.find(component => component.id === 'returns_summary'),
        isFetching: false,
      },
      second_shipping_summary: {
        ...action.payload.components.find(component => component.id === 'second_shipping_summary'),
        isFetching: false,
      },
      meliplus_summary: {
        ...action.payload.components.find(component => component.id === 'meliplus_summary'),
        isFetching: false,
      },
      upselling_meliplus_summary: {
        ...action.payload.components.find(component => component.id === 'upselling_meliplus_summary'),
        isFetching: false,
      },
    },
  };
};

const fetchOnChangeInstallationStart = state => ({
  ...state,
  components: {
    ...state.components,
    installations: {
      ...state.components.installations,
      isFetching: true,
    },
    main_actions: {
      ...state.components.main_actions,
      isFetching: true,
    },
    shipping_summary: {
      ...state.components.shipping_summary,
      isFetching: true,
    },
    pick_up_summary: {
      ...state.components.pick_up_summary,
      isFetching: true,
    },
    returns_summary: {
      ...state.components.returns_summary,
      isFetching: true,
    },
    second_shipping_summary: {
      ...state.components.second_shipping_summary,
      isFetching: true,
    },
    meliplus_summary: {
      ...(state.components.meliplus_summary || { state: 'hidden' }),
      isFetching: true,
    },
    upselling_meliplus_summary: {
      ...(state.components.upselling_meliplus_summary || { state: 'hidden' }),
      isFetching: true,
    },
  },
});

const fetchOnChangeInstallationEnd = state => ({
  ...state,
  components: {
    ...state.components,
    installations: {
      ...state.components.installations,
      isFetching: false,
    },
    main_actions: {
      ...state.components.main_actions,
    },
    shipping_summary: {
      ...state.components.shipping_summary,
      isFetching: false,
    },
    pick_up_summary: {
      ...state.components.pick_up_summary,
      isFetching: false,
    },
    returns_summary: {
      ...state.components.returns_summary,
      isFetching: false,
    },
    second_shipping_summary: {
      ...state.components.second_shipping_summary,
      isFetching: false,
    },
    meliplus_summary: {
      ...(state.components.meliplus_summary || { state: 'hidden' }),
      isFetching: false,
    },
    upselling_meliplus_summary: {
      ...(state.components.upselling_meliplus_summary || { state: 'hidden' }),
      isFetching: false,
    },
  },
});

const installationsMapper = {
  [FETCH_ON_CHANGE_INSTALLATION_START]: fetchOnChangeInstallationStart,
  [FETCH_ON_CHANGE_INSTALLATION_COMPLETE]: fetchOnChangeInstallationComplete,
  [FETCH_ON_CHANGE_INSTALLATION_END]: fetchOnChangeInstallationEnd,
};

export { fetchOnChangeInstallationStart, fetchOnChangeInstallationComplete, fetchOnChangeInstallationEnd };
export default installationsMapper;
