import {
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_QUESTION_AI_ERROR,
  INITIAL_QUESION_AI,
  CREATE_QUESTION_FROM_AI_COMPLETE,
} from '../../../../actions/actions';

const fetchQuestionAiStart = state => ({
  ...state,
  components: {
    ...state.components,
    questions: {
      ...state.components.questions,
      questions_ai: {
        ...state.components.questions.questions_ai,
        isFetching: true,
      },
    },
  },
});

const fetchQuestionAiComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    questions: {
      ...state.components.questions,
      questions_ai: {
        ...state.components.questions.questions_ai,
        ...action.payload,
        isFetching: false,
      },
    },
  },
});

const fetchQuestionAiError = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    questions: {
      ...state.components.questions,
      questions_ai: {
        ...state.components.questions.questions_ai,
        isFetching: false,
      },
    },
  },
  error: action.error,
});

const initialQuesionAi = state => ({
  ...state,
  components: {
    ...state.components,
    questions: {
      ...state.components.questions,
      questions_ai: {
        ...state.components.questions.questions_ai,
        questions_ai_form: {
          ...state.components.questions.questions_ai.questions_ai_form,
          form: {
            ...state.components.questions.questions_ai?.questions_ai_form?.form,
            is_ask_seller: false,
          },
        },
      },
    },
  },
});

const createQuestionFromAiComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    questions: {
      ...state.components.questions,
      questions_ai: {
        ...state.components.questions.questions_ai,
        questions_ai_form: {
          ...state.components.questions.questions_ai.questions_ai_form,
          form: {
            ...state.components.questions.questions_ai?.questions_ai_form?.form,
            is_ask_seller: true,
          },
        },
        questions_ai_suggestion: {
          state: 'HIDDEN',
        },
        isFetching: false,
      },
      disabled: false,
      isFetching: false,
      stockModal: action.payload.stock_modal,
      stockTrack: action.payload.stock_track,
      ...action.payload.model,
    },
  },
});

const questionAiMapper = {
  [FETCH_QUESTION_AI_START]: fetchQuestionAiStart,
  [FETCH_QUESTION_AI_COMPLETE]: fetchQuestionAiComplete,
  [FETCH_QUESTION_AI_ERROR]: fetchQuestionAiError,
  [INITIAL_QUESION_AI]: initialQuesionAi,
  [CREATE_QUESTION_FROM_AI_COMPLETE]: createQuestionFromAiComplete,
};

export {
  fetchQuestionAiStart,
  fetchQuestionAiComplete,
  fetchQuestionAiError,
  initialQuesionAi,
  createQuestionFromAiComplete,
};
export default questionAiMapper;
