import {
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  UPDATE_GIFT_REGISTRY,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
} from '../../../../actions/actions';

const wishlistShowBottomSheetModal = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    wishlist_save_button: {
      ...state.components.wishlist_save_button,
      targetDynamic: action.payload?.targetDynamic || state.components?.wishlist_save_button?.target,
      show: true,
    },
  },
});

const wishlistCloseBottomSheetModal = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    bookmark: {
      ...state.components.bookmark,
      is_bookmarked: action.payload?.isBookmarked || state.components?.bookmark?.is_bookmarked,
    },
    wishlist_save_button: {
      ...state.components.wishlist_save_button,
      targetDynamic: action.payload?.targetDynamic || state.components?.wishlist_save_button?.target,
      show: false,
    },
  },
});

const updateGiftRegistry = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    bookmark: {
      ...state.components.bookmark,
      is_bookmarked: action.payload?.isBookmarked || state.components?.bookmark?.is_bookmarked,
    },
    wishlist_save_button: {
      ...state.components.wishlist_save_button,
      label: action.payload?.wishlist_save_button?.action?.label?.text,
      gift_registry: {
        ...action.payload?.wishlist_save_button?.gift_registry,
        checkbox: {
          ...action.payload?.wishlist_save_button?.gift_registry?.checkbox,
          check: action.payload?.check || action.payload?.wishlist_save_button?.gift_registry?.checkbox?.check,
        },
      },
    },
  },
});

const updateGiftRegistryCheckbox = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    wishlist_save_button: {
      ...state.components.wishlist_save_button,
      gift_registry: {
        ...state?.components.wishlist_save_button?.gift_registry,
        checkbox: {
          ...state?.components.wishlist_save_button?.gift_registry?.checkbox,
          check: action.payload?.check,
        },
      },
    },
  },
});

const wishlistMapper = {
  [WISHLIST_SHOW_BOTTOMSHEET_MODAL]: wishlistShowBottomSheetModal,
  [WISHLIST_CLOSE_BOTTOMSHEET_MODAL]: wishlistCloseBottomSheetModal,
  [UPDATE_GIFT_REGISTRY]: updateGiftRegistry,
  [UPDATE_GIFT_REGISTRY_CHECKBOX]: updateGiftRegistryCheckbox,
};

export { wishlistShowBottomSheetModal, wishlistCloseBottomSheetModal, updateGiftRegistry, updateGiftRegistryCheckbox };
export default wishlistMapper;
