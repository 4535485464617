import React from 'react';
import { BottomSheetProvider } from 'eshops-components-library';
import { shape, arrayOf, string } from 'prop-types';
import ComponentFactory from '../component-factory';
import resources from '../utils';

const WrapperEshops = ({ children, siteId }) => {
  const validComponent = comp => !comp.hidden && resources[comp.component];
  const componentCreation = comp => <ComponentFactory {...comp} key={comp.id} siteId={siteId} />;
  const componentWrapper = children?.map(component => validComponent(component) && componentCreation(component));
  return <BottomSheetProvider>{componentWrapper || <></>}</BottomSheetProvider>;
};

WrapperEshops.propTypes = {
  children: arrayOf(shape).isRequired,
  siteId: string.isRequired,
};

export default WrapperEshops;
