import { SET_RECOMMENDATIONS_CONTEXT } from '../../../../actions/actions';

const setRecommendationsContext = (state, action) => ({
  ...state,
  recommendations_context: action.payload.recommendations_context,
});

const recommendationsMapper = {
  [SET_RECOMMENDATIONS_CONTEXT]: setRecommendationsContext,
};

export { setRecommendationsContext };
export default recommendationsMapper;
