import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import HeaderTopBrandFixed from '../../../../components/header-top-brand/header-fixed';

const FEATURE_NAME = 'header_top_brand_up';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => ({
  ...props,
});

const enhance = compose(
  withFeatureFlag(`${FEATURE_NAME}`),
  connect(mapStateToProps),
);

export default enhance(HeaderTopBrandFixed);
