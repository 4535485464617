import { messagesErrorScreen } from '@vpp-frontend-components/common';
import { CREATE_QUESTION_START, CREATE_QUESTION_COMPLETE, CREATE_QUESTION_ERROR } from '../../../../actions/actions';

const createQuestionStart = state => ({
  ...state,
  components: {
    ...state.components,
    questions: {
      ...state.components.questions,
      questions_ai: {
        ...state.components.questions.questions_ai,
        isFetching: false,
      },
      messages: null,
      disabled: true,
      isFetching: true,
    },
  },
});

const createQuestionComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    questions: {
      ...state.components.questions,
      disabled: false,
      messages: {
        ...action.payload.display_message,
        type: action.payload.success === true ? 'success' : 'error',
      },
      isFetching: false,
      stockModal: action.payload.stock_modal,
      stockTrack: action.payload.stock_track,
    },
  },
});

const createQuestionError = state => {
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;
  return {
    ...state,
    components: {
      ...state.components,
      questions: {
        ...state.components.questions,
        questions_ai: {
          ...state.components.questions.questions_ai,
          isFetching: false,
        },
        messages: {
          text: errorText,
          type: 'error',
        },
        isFetching: false,
        disabled: false,
      },
    },
  };
};

const questionsMapper = {
  [CREATE_QUESTION_START]: createQuestionStart,
  [CREATE_QUESTION_COMPLETE]: createQuestionComplete,
  [CREATE_QUESTION_ERROR]: createQuestionError,
};

export { createQuestionStart, createQuestionComplete, createQuestionError };
export default questionsMapper;
