import React from 'react';
import { string } from 'prop-types';
import Script from 'nordic/script';
import classNames from 'classnames';

/* Base Page */
import Vpp from './main';

/** Available features */
import StoreHeader from '../../../features/header-store/header-store';

import HeaderTopBrand from '../../../features/header-top-brand/header-top-brand.desktop';
import HeaderTopBrandFixed from '../../../features/header-top-brand-fixed/header-top-brand-fixed';
import HeaderOfficialStore from '../../../features/header-official-store/header-official-store.desktop';
import RelatedSearches from '../../../features/related-searches';
import Breadcrumb from '../../../features/breadcrumb';
import Share from '../../../features/share/share.desktop';
import Gallery from '../../../features/gallery/gallery.desktop';
import Header from '../../../features/header/header.desktop';
import OtherProducts from '../../../features/other-products/other-products.desktop';
import Bookmark from '../../../features/bookmark';
import Price from '../../../features/price/price.desktop';
import ShippingMessage from '../../../features/shipping-message';
import WarningMessage from '../../../features/warning-message';
import StockInformation from '../../../features/stock-information/stock-information.desktop';
import FulfillmentInformation from '../../../features/fulfillment-information';
import Reputation from '../../../features/reputation-seller';
import Promotions from '../../../features/promotions';
import PromotionTop from '../../../features/promotion-top';
import PromotionBottom from '../../../features/promotion-bottom';
import Highlights from '../../../features/highlights';
import Features from '../../../features/features';
import VariationsBox from '../../../features/variations/variations-box.desktop';
import Variations from '../../../features/variations/variations.desktop';
import Description from '../../../features/description/description.desktop';
import HtmlDescription from '../../../features/html-description/html-description.desktop';
import OtherSellersNewItems from '../../../features/other-sellers-new-items';
import OtherSellersUsedRefurbished from '../../../features/other-sellers-used-refurbished';
import OtherSellersBuyNewOption from '../../../features/other-sellers-buy-new-option';
import Specs from '../../../features/specs/specs.desktop';
import Reviews from '../../../features/reviews/reviews.desktop';
import ReviewsCapability from '../../../../../components/reviews-capability/reviews.desktop';
import QADB from '../../../features/qadb';
import Questions from '../../../features/questions/questions.desktop';
import Returns from '../../../features/returns/returns.desktop';
import CpgSummary from '../../../features/cpg/summary.desktop';
import ApparelSizeSpecPreview from '../../../features/apparel-size-spec-preview/apparel-size-specs-preview.desktop';
import OfficialStores from '../../../features/official-stores/official-stores.desktop';
import Buybox from '../../../features/buybox';
import BuyBoxOffers from '../../../features/buy-box-offers';
import QuantityDesktop from '../../../features/buybox/quantity/quantity.desktop';
import ShippingSummary from '../../../features/buybox/shipping';
import SecondShippingSummary from '../../../features/buybox/second-shipping';
import PickUpSummary from '../../../features/buybox/pick-up';
import ReturnsSummary from '../../../features/buybox/returns-summary/returns-summary.desktop';
import MeliplusLabel from '../../../features/buybox/meliplus-label/meliplus-label';
import MeliplusSummary from '../../../features/buybox/meliplus';
import CardWithThumbnails from '../../../features/card-with-thumbnails/card-with-thumbnails';
import UpsellingMeliplusSummary from '../../../features/buybox/upselling-meliplus';
import Warranty from '../../../../vip/features/warranty/warranty.desktop';
import CouponSummary from '../../../features/buybox/coupon-summary/coupon-summary.desktop';
import PaymentSummary from '../../../features/buybox/payment/payment.desktop';
import Actions from '../../../features/buybox/actions';
import CartActions from '../../../features/buybox/cart-actions/cart-actions.desktop';
import SellerInfo from '../../../features/seller-info/seller-info.desktop';
import SellerData from '../../../features/seller-data';
import SellerExperiment from '../../../features/seller-info/seller-experiment.desktop';
import StickyWrapper from '../../../features/sticky-wrapper';
import Benefits from '../../../features/benefits';
import SYI from '../../../features/sell-your-item/sell-your-item';
import EYI from '../../../features/edit-your-item/edit-your-item';
import CreditsTooltip from '../../../features/credits-tooltip';
import PaypalTooltip from '../../../features/paypal-tooltip';
import PaymentMethods from '../../../features/payment-methods/payment-methods.desktop';
import MoqInformation from '../../../features/moq-information/moq-information.desktop';
import Onboarding from '../../../features/onboarding/onboarding.desktop';
import CPG from '../../../features/cpg/cpg.desktop';
import HighlightedSpecsFeatures from '../../../features/highlighted-specs/highlighted-specs-features.desktop';
import HighlightedSpecsAttrs from '../../../features/highlighted-specs/highlighted-specs-attrs.desktop';
import Seller from '../../../features/seller/seller-info.desktop';
import BillboardAd from '../../../../vip/features/billboard-ad';
import MerchEcosystem from '../../../features/merch-ecosystem';
import BillboardAdn from '../../../../vip/features/billboard-adn/billboard-adn-bottom';
import BillboardAdnUp from '../../../../vip/features/billboard-adn/billboard-adn-up';
import ExtendedMenu from '../../../../vip/features/extended-menu/extended-menu.desktop';
import FilterMessage from '../../../features/pdp-filter-message';
import ReturnsNotificationsBottom from '../../../../vip/features/returns-notification-bottom/returns-notification-bottom.desktop';
import ReturnsNotificationsUp from '../../../../vip/features/returns-notification-up/returns-notification-up.desktop';
import TradeIn from '../../../features/trade-in';
import Denounce from '../../../features/denounce';
import PricePerQuantityShortcuts from '../../../features/price-per-quantity-shortcuts';
import EnhancedDescription from '../../../features/enhanced-descriptions/enhanced-descriptions.desktop';

// cbt
import CbtSummary from '../../../../../components/cbt/cbt-summary.desktop';

import CbtTaxesSummary from '../../../../../components/cbt-taxes/cbt-taxes-summary.desktop';
import CbtTaxesFcUsSummary from '../../../../../components/cbt-taxes-fc-us/cbt-taxes-fc-us-summary.desktop';

// Banner Meli+ BB
import GenericBanner from '../../../features/buybox/generic-banner';
import GenericBannerPrice from '../../../features/buybox/generic-banner-price';
import GenericBannerInstallments from '../../../features/buybox/generic-banner-installments';

// Recommendations
import RecommendationsCarouselSnapped from '../../../../vip/features/recommendations/carousel-snapped';

import RecommendationsListPads from '../../../../vip/features/recommendations/list-pads';
import RecommendationsShops from '../../../../vip/features/recommendations/shops';
import RecommendationsShopBottom from '../../../../vip/features/recommendations/shops-bottom';
import RecommendationsComparator from '../../../features/recommendations/comparator.desktop';
import RecommendationsCombos from '../../../../../components/combos/combos.desktop';
import RecommendationsListHorizontal from '../../../../vip/features/recommendations/list-horizontal';
import RecommendationsCarouselSnappedHorizontal from '../../../../vip/features/recommendations/carousel-snapped-horizontal';
import RecommendationsCarouselSnappedHorizontalTop from '../../../features/recommendations/carousel-snapped-horizontal-neighbor';

// Preload Iframe
import PreloadIframe from '../../../features/preload-iframe/preload-iframe';

// On demand Iframe
import OnDemandIframe from '../../../features/on-demand-iframe/on-demand-iframe';

import ExitPopUp from '../../../features/exit-popup/exit-popup';
import DynamicComponentWrapper from '../../../../vip/features/dynamic-component-wrapper';
import renderSwapComponent from '../../../../vip/features/dynamic-component-wrapper/render-swap-component';
import Snackbar from '../../../../vip/features/snackbar';
import TextJumpToPurchaseSectionDesktop from '../../../features/accessibility-texts/text-jump-to-purchase-section.desktop';
import { spotMiddleDesktopRender } from '../../../../../utils/spotMiddleRenders';

// Compats
import InstalationService from '../../../features/instalation-service';

// Utils
import { isOfficialStoreHeaderVisible } from '../../../../../utils/component-visibility';

import GroupedShareBookmark from '../../../features/grouped-share-bookmark';
import GiftRegistry from '../../../../vip/features/gift-registry';

import { constants } from '../../../../../utils/constants';

import HeaderBrandLogo from '../../../features/header-brand-logo/header_brand_logo.desktop';
import TopAvailableQuantitySummary from '../../../../vip/features/top-available-quantity-summary/top-available-quantity-summary.desktop';

import CompatsWidgetSticky from '../../../../vip/features/compats-widget-sticky/compats-widget-sticky.desktop';
import Compats from '../../../../vip/features/compats-widget/compats-widget.desktop';
/* Components */
import Grid from '../../../../../components/grid';

const { VISIBILITY } = constants;

const MeliDeliveryDaySummary = () => (
  <>
    <MeliplusSummary className="mb-12" />
    <CardWithThumbnails className="mb-20 mt-4" />
  </>
);

// Dynamic Shipping Wrapper (for order swap)
const renderTopShipping = renderSwapComponent(
  'meliplus_summary',
  <MeliDeliveryDaySummary />,
  <ShippingSummary className="mb-12" />,
);

const VppDesktop = ({ baseURL, siteId, ...rest }) => {
  const { csrfToken, initialState } = rest;
  const { components = {} } = initialState;
  const {
    header_cpg,
    moq_information,
    related_searches,
    enhanced_descriptions,
    description,
    html_description,
  } = components;
  const hasRelatedSearches = related_searches && related_searches.state !== VISIBILITY.HIDDEN;
  const hasOfficialStorHeader = isOfficialStoreHeaderVisible(components);
  const hasCpgHeader = header_cpg && header_cpg.state !== VISIBILITY.HIDDEN;
  const hasMoqInfo = moq_information && moq_information.state !== VISIBILITY.HIDDEN;
  const hasDescription = description && description.state !== VISIBILITY.HIDDEN;
  const hasEnhancedDescriptions = enhanced_descriptions && enhanced_descriptions.state !== VISIBILITY.HIDDEN;
  const hasHtmlDescription = html_description && html_description.state !== VISIBILITY.HIDDEN;
  const shouldUseTopSeparator = hasDescription || hasEnhancedDescriptions || hasHtmlDescription;
  const headerGroupClassName = classNames('ui-pdp-container--top', {
    'ui-vpp-container-top--gray': hasOfficialStorHeader,
    'ui-vpp__cpg-header': hasCpgHeader,
  });
  const itemContainer = classNames('ui-pdp-container--pdp', {
    'ui-vpp-container--bt-rect': hasOfficialStorHeader,
  });
  const qtyClassNames = classNames({ 'mb-12': !hasMoqInfo });
  const recosTopContainerClass = classNames({ 'ui-pdp-container--recos-top': !hasRelatedSearches });
  const recosSpotMiddClass = classNames({
    'mt-40 ui-pdp-with--separator-top ui-pdp-with--separator-top--full': shouldUseTopSeparator,
  });

  return (
    <Vpp baseURL={baseURL} siteId={siteId} {...rest}>
      <ExitPopUp url={initialState.parentUrl} />
      <OnDemandIframe />
      <PreloadIframe />
      <Onboarding />
      <CPG />
      <Grid.Container className={headerGroupClassName}>
        <StoreHeader />
        <HeaderTopBrandFixed />
        <HeaderOfficialStore />
        <Grid.Row className="ui-pdp-group-header">
          <Grid.Col size={6} className="ui-pdp-container--breadcrumb-related">
            <RelatedSearches />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col size={6} className={recosTopContainerClass}>
            <RecommendationsListHorizontal
              featureName="carousel_cheaper"
              className="pt-12 pb-4 pl-0 pr-0"
              lazy={false}
              preload
              fetchPriority="high"
              criticalImages={3}
            />
            <RecommendationsCarouselSnapped
              featureName="carousel_v2p_not_active_item"
              className="mb-24 mt-24"
              lazy={false}
              preload
              fetchPriority="high"
              criticalImages={5}
            />
            <RecommendationsCarouselSnappedHorizontalTop
              featureName="carousel_neighbour_top"
              className="carousel-snapped-horizontal"
              lazy={false}
              preload
              fetchPriority="high"
            />
            <RecommendationsCarouselSnappedHorizontalTop
              featureName="carousel_pom_top"
              className="carousel-snapped-horizontal"
              lazy={false}
              preload
              fetchPriority="high"
            />
            <RecommendationsCarouselSnapped
              featureName="carousel_header_pdp_inactive"
              className="mb-24 mt-24"
              lazy={false}
              preload
              fetchPriority="high"
              criticalImages={5}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="ui-pdp-group-header-breadcrumb">
          <Grid.Col size={2}>
            <Breadcrumb />
          </Grid.Col>
          <Grid.Col size={6} className="ui-pdp-container--share">
            <EYI />
            <SYI />
            <Share />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <CompatsWidgetSticky />
      <Grid.Container className={itemContainer}>
        <Grid.Row>
          <HeaderBrandLogo />
        </Grid.Row>
        <Grid.Row id="ui-pdp-main-container" className="ui-pdp--relative ui-pdp-with--separator--fluid pb-40">
          <Grid.Col className="ui-pdp-container--column-center pb-40" size={3}>
            <StickyWrapper columnName="center">
              <Grid.Row className="ui-pdp-with--separator--fluid ui-pdp-with--separator--40-24">
                <Grid.Col className="ui-pdp--relative" size={2}>
                  <StickyWrapper>
                    <Gallery />
                  </StickyWrapper>
                </Grid.Col>
                <Grid.Col size={2} className="mr-24 mt-8" style={{ maxWidth: '340px' }}>
                  <HeaderTopBrand />
                  <div className="ui-pdp-container__top-wrapper mt-40">
                    <Header>
                      <Highlights className="ui-pdp-highlights" />
                    </Header>
                    <Bookmark showLabel={false} csrfToken={csrfToken} />
                  </div>
                  <PromotionTop className="mt-16" />
                  <Price meta className="mt-16" size="large" />
                  <PricePerQuantityShortcuts className="mt-20" />
                  <PromotionBottom className="mt-24" />
                  <Promotions className="mt-12" />
                  <GenericBannerPrice className="mt-12" height={71} width={274} />
                  <GenericBannerInstallments className="mt-12" height={71} width={274} />
                  <CbtTaxesFcUsSummary />
                  <CouponSummary className="mt-24" />
                  <WarningMessage className="mt-24 mb-16" />
                  <VariationsBox className="mt-24" />
                  <Variations className="mt-24" />
                  <FilterMessage className="mt-24" />
                  <InstalationService className="mb-14" />
                  <TradeIn className="mt-21" />
                  <Compats />
                  <Features className="mt-24" />
                  <HighlightedSpecsFeatures className="mt-24" />
                  <OtherProducts />
                  <TextJumpToPurchaseSectionDesktop />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="ui-pdp-recommendations-row">
                <Grid.Col>
                  <RecommendationsCombos className="pl-24 pr-24 ui-pdp-with--separator" />
                  <RecommendationsCarouselSnapped
                    featureName="carousel_up_web"
                    className="pl-24 pr-24 pb-40"
                    slidesPerView={3}
                  />
                  <RecommendationsCarouselSnapped
                    featureName="carousel_up"
                    className="pl-24 pr-24 pb-40"
                    slidesPerView={3}
                    lazy={false}
                  />
                  <RecommendationsCarouselSnapped
                    featureName="carousel_above"
                    className="pl-24 pr-24 pb-40"
                    slidesPerView={3}
                  />
                  <RecommendationsCarouselSnapped
                    featureName="carousel_above_pads"
                    className="pl-24 pr-24 pb-40"
                    slidesPerView={3}
                  />
                  <RecommendationsCarouselSnappedHorizontal
                    featureName="carousel_above_swap"
                    className="pl-24 pr-24 pt-40 ui-pdp-carousel-above-seller no-border ui-pdp-with--separator"
                    slidesPerView={2}
                    arrowsSize="small"
                  />
                  <RecommendationsCarouselSnappedHorizontal
                    featureName="carousel_above_seller"
                    className="pl-24 pr-24 pt-40 ui-pdp-carousel-above-seller no-border ui-pdp-with--separator"
                    slidesPerView={2}
                    arrowsSize="small"
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col className="ui-pdp-with--separator--auto ui-pdp-with--separator--40-24">
                  <ApparelSizeSpecPreview className="pl-24 pr-24" />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col className="ui-pdp-with--separator--auto ui-pdp-with--separator--40-24">
                  <HighlightedSpecsAttrs className="pl-24 pr-24" />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col className="ui-pdp-with--separator--auto ui-pdp-with--separator--40-24">
                  <Specs className="pl-24 pr-24" />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col className="pl-24 pr-24">
                  <EnhancedDescription className="pl-24 pr-24 ui-pdp-collapsable--is-collapsed" />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Description className="pl-24 pr-24 ui-pdp-collapsable--is-collapsed" />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <HtmlDescription />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="ui-pdp-recommendations-row">
                <Grid.Col>
                  <DynamicComponentWrapper
                    renderDynamicComponent={spotMiddleDesktopRender({
                      firstChildClassName: recosSpotMiddClass,
                      middleChildClassName: 'mt-40',
                      slidesPerView: 3,
                    })}
                    className="ui-pdp-spot-middle"
                    spot="spot_middle"
                  />
                </Grid.Col>
              </Grid.Row>
            </StickyWrapper>
          </Grid.Col>
          <Grid.Col className="ui-pdp-container--column-right mt-24 mr-8 pr-16">
            <StickyWrapper columnName="right">
              <Grid.Row>
                <BuyBoxOffers>
                  <CpgSummary />
                  <CbtSummary />
                  <PaymentSummary className="mb-20" />
                  <MeliplusLabel className="ui-pdp-meliplus-label mb-12" />
                  <DynamicComponentWrapper renderDynamicComponent={renderTopShipping} spot="top_shipping" />
                  <GenericBanner className="mb-12" height={71} width={274} />
                  <CbtTaxesSummary />
                  <SecondShippingSummary className="mb-12" />
                  <UpsellingMeliplusSummary className="mb-12" />
                  <PickUpSummary className="mb-12" />
                  <ShippingMessage className="mb-14" />
                  <ReturnsSummary className="mb-12" />
                  <SellerInfo className="mb-20" />
                  <div className="ui-pdp-stock-and-full">
                    <StockInformation />
                    <FulfillmentInformation />
                  </div>
                  <TopAvailableQuantitySummary />
                  <QuantityDesktop className={qtyClassNames} />
                  <MoqInformation className="mb-24" />
                  <PaypalTooltip className="mb-14" />
                  <Actions parentUrl={baseURL} />
                  <CartActions />
                  <CreditsTooltip className="mt-16" />
                  <SellerExperiment className="mb-20 mt-20" />
                  <Reputation />
                </BuyBoxOffers>
                <Buybox>
                  <CpgSummary />
                  <CbtSummary />
                  <PaymentSummary className="mb-20" />
                  <MeliplusLabel className="ui-pdp-meliplus-label mb-12" />
                  <DynamicComponentWrapper renderDynamicComponent={renderTopShipping} spot="top_shipping" />
                  <GenericBanner className="mb-24" height={71} width={274} />
                  <CbtTaxesSummary />
                  <SecondShippingSummary className="mb-12" />
                  <UpsellingMeliplusSummary className="mb-12" />
                  <PickUpSummary className="mb-20" />
                  <ShippingMessage className="mb-14" />
                  <ReturnsSummary className="mb-12" />
                  <SellerInfo className="mb-20" />
                  <div className="ui-pdp-stock-and-full">
                    <StockInformation />
                    <FulfillmentInformation />
                  </div>
                  <TopAvailableQuantitySummary />
                  <QuantityDesktop className={qtyClassNames} />
                  <MoqInformation className="mb-24" />
                  <PaypalTooltip className="mb-14" />
                  <ReturnsNotificationsUp />
                  <Actions parentUrl={baseURL} />
                  <ReturnsNotificationsBottom />
                  <SellerExperiment className="mb-20 mt-20" />
                  <Reputation />
                  <CartActions />
                  <CreditsTooltip className="mt-16" />
                  <Reputation />
                  <Returns className="mt-24" />
                  <Benefits className="mt-24" />
                  <GiftRegistry className="mt-24" />
                  <GroupedShareBookmark className="mt-24" showOnlyForWishlistVariant />
                </Buybox>
              </Grid.Row>
              <Grid.Row>
                <Seller />
                <SellerData className="mt-24 ui-box-component-pdp__visible--desktop" />
                <OtherSellersNewItems className="mt-24" />
                <OtherSellersUsedRefurbished className="mt-24" />
                <OtherSellersBuyNewOption className="mt-24" />
                <MerchEcosystem className="mt-24" />
                <Grid.Row className="ui-pdp-component-list mt-24">
                  <Warranty />
                  <PaymentMethods />
                </Grid.Row>
              </Grid.Row>
              <Grid.Row>
                <RecommendationsListPads
                  featureName="advertising_pdp_right"
                  className="ui-box-component-pdp__visible--desktop"
                />
                <RecommendationsListPads
                  featureName="advertising_vip_right"
                  className="ui-box-component-pdp__visible--desktop"
                />
              </Grid.Row>
              <Grid.Row>
                <RecommendationsShops featureName="advertising_pdp_right_mshops" layout="pdp" />
                <RecommendationsShops featureName="advertising_vip_right_mshops" layout="pdp" />
              </Grid.Row>
            </StickyWrapper>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col size={3}>
            <Grid.Row className="ui-pdp-container__comparator">
              <Grid.Col className="pl-24 pr-24">
                <RecommendationsComparator />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row id="ui-pdp-main-container">
          <Grid.Col size={3}>
            <Grid.Row>
              <Grid.Col>
                <QADB className="pl-24 pr-24 mb-48" />
                <Questions className="pl-24 pr-24 mb-48" />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Reviews className="pl-24 pr-24 mb-48" />
                <ReviewsCapability className="pl-24 pr-24 mb-48" />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col className="ui-pdp-container--column-right ui-pdp-container--column-right-bottom mr-8 pr-16">
            <StickyWrapper columnName="right">
              <Grid.Row>
                <RecommendationsShopBottom featureName="advertising_pdp_right_mshops_bottom" layout="pdp" />
              </Grid.Row>
              <Grid.Row>
                <BillboardAdnUp />
              </Grid.Row>
            </StickyWrapper>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <OfficialStores />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <Grid.Container>
        <Denounce />
        <DynamicComponentWrapper
          renderDynamicComponent={featureName => (
            <RecommendationsCarouselSnapped className="mb-64 pb-16" featureName={featureName} />
          )}
          spot="carousel_bottom"
        />
      </Grid.Container>
      <Grid.Container className="ui-vip-core ui-pdp-container--bottom">
        <BillboardAd />
        <BillboardAdn />
        <ExtendedMenu />
      </Grid.Container>
      {siteId === 'MLB' && <Script src="block-extensions.js" inline />}
      <Snackbar />
    </Vpp>
  );
};

VppDesktop.propTypes = {
  baseURL: string.isRequired,
  siteId: string.isRequired,
  csrfToken: string,
};

export default VppDesktop;
