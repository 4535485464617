import { FETCH_ON_MELIPLUS_UPDATE_START, FETCH_ON_MELIPLUS_UPDATE_COMPLETE } from '../../../../actions/actions';
import updateArrayComponents from '../../../helpers/update-array-components';

const fetchOnMeliplusUpdateStart = state => ({
  ...state,
  components: {
    ...state.components,
    shipping_summary: {
      ...state.components.shipping_summary,
      isFetching: true,
    },
    second_shipping_summary: {
      ...state.components.second_shipping_summary,
      isFetching: true,
    },
    meliplus_summary: {
      ...state.components.meliplus_summary,
      isFetching: true,
    },
    upselling_meliplus_summary: {
      ...state.components.upselling_meliplus_summary,
      isFetching: true,
    },
    pick_up_summary: {
      ...state.components.pick_up_summary,
      isFetching: true,
    },
    returns_summary: {
      ...state.components.returns_summary,
      isFetching: true,
    },
    generic_banner_price: {
      ...state.components.generic_banner_price,
      isFetching: true,
    },
    generic_banner_installments: {
      ...state.components.generic_banner_installments,
      isFetching: true,
    },
  },
});

const fetchOnMeliplusUpdateComplete = (state, action) => {
  const mergedComponents = {
    ...state.components,
    ...action.payload.components,
  };
  return {
    ...state,
    components: {
      ...mergedComponents,
      shipping_summary: mergedComponents.shipping_summary && {
        ...mergedComponents.shipping_summary,
        isFetching: false,
      },
      second_shipping_summary: mergedComponents.second_shipping_summary && {
        ...mergedComponents.second_shipping_summary,
        isFetching: false,
      },
      meliplus_summary: mergedComponents.meliplus_summary && {
        ...mergedComponents.meliplus_summary,
        isFetching: false,
      },
      upselling_meliplus_summary: mergedComponents.upselling_meliplus_summary && {
        ...mergedComponents.upselling_meliplus_summary,
        isFetching: false,
      },
      pick_up_summary: mergedComponents.upselling_meliplus_summary && {
        ...mergedComponents.pick_up_summary,
        isFetching: false,
      },
      returns_summary: mergedComponents.upselling_meliplus_summary && {
        ...mergedComponents.returns_summary,
        isFetching: false,
      },
      fixed: mergedComponents.upselling_meliplus_summary && {
        ...state.components.fixed,
        ...action.payload.components.fixed,
      },
      ...updateArrayComponents(state.components, action.payload.components),
    },
    preload: action.payload.preload,
  };
};

const meliplusMapper = {
  [FETCH_ON_MELIPLUS_UPDATE_START]: fetchOnMeliplusUpdateStart,
  [FETCH_ON_MELIPLUS_UPDATE_COMPLETE]: fetchOnMeliplusUpdateComplete,
};

export { fetchOnMeliplusUpdateStart, fetchOnMeliplusUpdateComplete };
export default meliplusMapper;
