import { FETCH_ON_CHANGE_TRADE_IN_START, FETCH_ON_CHANGE_TRADE_IN_COMPLETE } from '../../../../actions/actions';

const fetchOnChangeTradeInStart = state => ({
  ...state,
  components: {
    ...state.components,
    trade_in: {
      ...state.components.trade_in,
      isFetching: true,
    },
    main_actions: {
      ...state.components.main_actions,
      isFetching: true,
    },
    buy_box_offers: {
      ...state.components.buy_box_offers,
      isFetching: true,
    },
  },
});

const fetchOnChangeTradeInComplete = (state, action) => {
  const { trade_subtitles, background_color, ...updatedTradeIn } =
    action.payload.components.find(component => component.id === 'trade_in') || {};
  return {
    ...state,
    components: {
      ...state.components,
      trade_in: {
        ...state.components.trade_in,
        ...updatedTradeIn,
        trade_subtitles: trade_subtitles || state.components.trade_in?.trade_subtitle,
        background_color,
        isFetching: false,
      },
      main_actions: {
        ...state.components.main_actions,
        ...action.payload.components.find(component => component.id === 'main_actions'),
        isFetching: false,
      },
      coupon_summary: {
        ...state.components.coupon_summary,
        ...action.payload.components.find(component => component.id === 'coupon_summary'),
        isFetching: false,
      },
      buy_box_offers: {
        ...state.components.buy_box_offers,
        ...action.payload.components.find(component => component.id === 'buy_box_offers'),
        isFetching: false,
      },
    },
  };
};

const tradeInMapper = {
  [FETCH_ON_CHANGE_TRADE_IN_START]: fetchOnChangeTradeInStart,
  [FETCH_ON_CHANGE_TRADE_IN_COMPLETE]: fetchOnChangeTradeInComplete,
};

export { fetchOnChangeTradeInStart, fetchOnChangeTradeInComplete };
export default tradeInMapper;
